import styled, { css } from 'styled-components';
import { media } from 'utils';

export const MainHeader = styled.h1`
  margin: 0;
  font-size: 32px;
  text-transform: ${({ isUppercased }) =>
    isUppercased ? 'uppercase' : 'none'};
  margin-bottom: 20px;
  font-weight: ${({ isBolded }) => (isBolded ? 700 : 500)};
  color: ${({ isLight, theme }) => theme.color[isLight ? 'white' : 'darkBlue']};
  line-height: 1.2;
  max-width: ${({ maxWidth }) => maxWidth || 'none'};
  text-align: ${({ isCentered }) => (isCentered ? 'center' : 'inherit')};

  ${media.large(css`
    font-size: 44px;
    margin-bottom: 25px;
  `)};
`;

export const SecondaryHeader = styled.h2`
  margin: 0;
  font-size: 28px;
  text-transform: uppercase;
  font-weight: ${({ isBolded }) => (isBolded ? 500 : 300)};
  color: ${({ isLight, theme }) => theme.color[isLight ? 'white' : 'darkBlue']};
  line-height: 1.2;
  margin-bottom: 25px;
  max-width: ${({ maxWidth }) => maxWidth || 'none'};
  text-align: ${({ isCentered }) => (isCentered ? 'center' : 'inherit')};

  ${({ isCentered, isUnderlined, theme }) =>
    isUnderlined &&
    css`
      padding-bottom: 10px;
      position: relative;

      &:after {
        content: '';
        display: block;
        position: absolute;
        top: 100%;
        width: 60px;
        height: 2px;
        left: ${isCentered ? '50%' : 0};
        transform: ${isCentered ? 'translateX(-50%)' : 'none'};
        background-color: ${theme.color.primary};

        ${media.large(css`
          width: 80px;
          height: 3px;
        `)}
      }
    `};

  ${media.large(css`
    font-size: 38px;
    margin-bottom: 30px;
  `)};
`;

export const ThirdHeader = styled.h3`
  margin: 0;
  font-size: 18px;
  text-transform: ${({ isUppercased }) =>
    isUppercased ? 'uppercase' : 'none'};
  font-weight: ${({ isBolded }) => (isBolded ? 500 : 300)};
  color: ${({ isLight, theme }) => theme.color[isLight ? 'white' : 'darkBlue']};
  max-width: ${({ maxWidth }) => maxWidth || 'none'};
  text-align: ${({ isCentered }) => (isCentered ? 'center' : 'inherit')};
  line-height: 1.4;
  margin-bottom: 10px;

  ${media.large(css`
    font-size: 20px;
    margin-bottom: 12px;
  `)};
`;

export const Title = styled.h2`
  margin: 0;
  font-size: 20px;
  font-weight: 500;
  color: ${({ isLight, theme }) => theme.color[isLight ? 'white' : 'darkBlue']};
  line-height: 1.4;
  margin-bottom: 10px;
  max-width: ${({ maxWidth }) => maxWidth || 'none'};
  text-align: ${({ isCentered }) => (isCentered ? 'center' : 'inherit')};

  ${media.large(css`
    font-size: 25px;
    margin-bottom: 12px;
  `)};
`;

export const SectionDescription = styled.p`
  margin: 0;
  font-size: 16px;
  color: ${({ color, theme }) => theme.color[color || 'gray']};
  line-height: 1.4;
  font-weight: 300;
  max-width: ${({ maxWidth }) => maxWidth || 'none'};
  text-align: ${({ isCentered }) => (isCentered ? 'center' : 'inherit')};

  ${media.large(css`
    font-size: 18px;
  `)};
`;

export const Description = styled.p`
  margin: 0;
  font-size: ${({ isSmall }) => (isSmall ? '14px' : '16px')};
  color: ${({ color, theme }) => theme.color[color || 'gray']};
  max-width: ${({ maxWidth }) => maxWidth || 'none'};
  text-align: ${({ isCentered }) => (isCentered ? 'center' : 'inherit')};
  line-height: 1.4;
  font-weight: 300;
`;
