import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Observer } from 'components/common/Observer/Observer';

const FadeWrapper = styled.div`
  opacity: ${({ isIntersected }) => (isIntersected ? 1 : 0)};
  transition: opacity 0.75s ease-in;
`;

export const Fade = ({ children }) => (
  <Observer>
    {(isIntersected) => (
      <FadeWrapper isIntersected={isIntersected}>{children}</FadeWrapper>
    )}
  </Observer>
);

Fade.propTypes = {
  children: PropTypes.node.isRequired,
};
