import React from 'react';
import PropTypes from 'prop-types';

import Intersection from '@researchgate/react-intersection-observer';

if (typeof window !== 'undefined' && !('IntersectionObserver' in window)) {
  require('intersection-observer'); // eslint-disable-line global-require
}

export class Observer extends React.Component {
  state = {
    isIntersected: false,
  };

  handleChange = ({ isIntersecting }, unobserve) => {
    const { shouldUnobserve } = this.props;
    this.setState({ isIntersected: isIntersecting });

    if (!isIntersecting) return null;

    return shouldUnobserve ? unobserve() : null;
  };

  render() {
    const { isIntersected } = this.state;
    const { children, ...rest } = this.props;

    return (
      <Intersection onChange={this.handleChange} {...rest}>
        {children(isIntersected)}
      </Intersection>
    );
  }
}

Observer.propTypes = {
  children: PropTypes.func.isRequired,
  shouldUnobserve: PropTypes.bool,
};

Observer.defaultProps = {
  shouldUnobserve: true,
};
