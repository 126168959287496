import styled, { css } from 'styled-components';
import { media } from 'utils';

export const Wrapper = styled.div`
  width: 24px;
  height: 24px;
  cursor: pointer;
  display: flex;
  align-items: center;

  ${media.large(css`
    display: none;
  `)};
`;

export const HamburgerNav = styled.span`
  position: relative;

  &,
  &:before,
  &:after {
    background-color: ${({ isLight, theme }) =>
      isLight ? theme.color.white : theme.color.dark};
    display: block;
    height: 2px;
    width: 24px;
    transition: transform 0.2s ease;
  }

  &:before,
  &:after {
    content: '';
    position: absolute;
  }

  & {
    transform: rotateZ(${({ isOpen }) => (isOpen ? '-45deg' : '0')});
  }

  &:before {
    top: ${({ isOpen }) => (isOpen ? '0' : '-6px')};
    transform: rotateZ(${({ isOpen }) => (isOpen ? '90deg' : '0')});
  }

  &:after {
    bottom: -6px;
    display: ${({ isOpen }) => (isOpen ? 'none' : 'block')};
  }
`;
