import styled, { css } from 'styled-components';
import { media } from 'utils';

export const LayoutWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const LayoutContent = styled.div`
  margin-top: ${(props) => props.theme.layout.navMobileHeight};
  flex: 1;

  ${media.large(css`
    margin-top: ${(props) => props.theme.layout.navDesktopHeight};
  `)};
`;
