import styled, { css } from 'styled-components';
import { media } from 'utils';
import { Keyframes, animated, config } from 'react-spring/renderprops';

export const MainNavWrapper = styled.nav`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 ${(props) => props.theme.layout.gapMobileX};
  background-color: ${(props) => props.theme.color.white};
  height: ${(props) => props.theme.layout.navMobileHeight};
  box-shadow: ${(props) => props.theme.color.shadow};
  z-index: 100;

  ${media.large(css`
    height: ${(props) => props.theme.layout.navDesktopHeight};
    padding: 0 ${(props) => props.theme.layout.gapDesktopX};
  `)};
`;

export const NavItems = styled(animated.ul)`
  display: flex;
  align-items: center;
  background-color: ${(props) => props.theme.color.white};
  flex-direction: column;
  list-style-type: none;
  margin: 0;
  position: fixed;
  top: calc(${(props) => props.theme.layout.navMobileHeight} + 1px);
  left: 100%;
  right: 0;
  bottom: 0;
  height: 100%;
  width: 100%;

  ${media.large(css`
    position: static;
    top: auto;
    right: auto;
    left: auto;
    bottom: auto;
    flex-direction: row;
    width: auto;
  `)};
`;

export const NavItem = styled(animated.li)`
  display: inline-block;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  color: ${(props) => props.theme.color.darkGray};
  padding: 15px ${(props) => props.theme.layout.borderGap};
  text-decoration: none;
  width: 100%;
  text-align: center;
  transition: color 0.2s ease;
  cursor: pointer;
  margin: 0;

  &:hover {
    color: ${(props) => props.theme.color.darkBlue};
  }

  ${media.large(css`
    width: auto;
    padding: 0;
    margin-left: 20px;

    &:first-child {
      margin-left: 0;
    }
  `)};
`;

export const AnimatedNavItems = Keyframes.Spring({
  close: {
    config: { delay: 400 },
    left: 100,
  },
  open: {
    left: 0,
  },
});

export const AnimatedNavItem = Keyframes.Trail({
  close: {
    config: {
      ...config.gentle,
    },
    opacity: 0,
    x: 100,
  },
  open: {
    config: {
      delay: 100,
      ...config.gentle,
    },
    opacity: 1,
    x: 0,
  },
});
