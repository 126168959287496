export const NAV_PATH = {
  ABOUT: '/about',
  CONTACT: '/contact',
  HOME: '/',
  NEWS: '/news',
  PRICES: '/prices',
};

export const NAV_ITEMS = [
  {
    label: 'Home',
    path: NAV_PATH.HOME,
  },
  {
    label: 'O nas',
    path: NAV_PATH.ABOUT,
  },
  {
    label: 'News',
    path: NAV_PATH.NEWS,
  },
  {
    label: 'Cennik',
    path: NAV_PATH.PRICES,
  },
  {
    label: 'Kontakt',
    path: NAV_PATH.CONTACT,
  },
];
