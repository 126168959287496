import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { navigate } from 'gatsby';
import { withMedia } from 'utils';
import { NAV_PATH, NAV_ITEMS } from 'consts';

import logoUrl from 'images/logo-color.svg';

import { Hamburger } from 'components/Layout/Hamburger/Hamburger';
import { Logo } from 'components/common/Logo/Logo';
import {
  AnimatedNavItem,
  AnimatedNavItems,
  MainNavWrapper,
  NavItem,
  NavItems,
} from './MainNavStyles';

class Nav extends Component {
  state = {
    isOpen: false,
  };

  handleHamburgerClick = () =>
    this.setState(({ isOpen }) => ({ isOpen: !isOpen }));

  handleLinkClick = (path) => {
    navigate(path);
    this.setState({ isOpen: false });
  };

  render() {
    const { isOpen } = this.state;
    const { isMatched } = this.props;
    const animationState = isOpen ? 'open' : 'close';

    return (
      <MainNavWrapper>
        <Logo imgUrl={logoUrl} to={NAV_PATH.HOME} />
        <AnimatedNavItems native state={animationState}>
          {({ left }) => (
            <NavItems
              style={
                !isMatched ? { left: left.interpolate((l) => `${l}%`) } : {}
              }
            >
              <AnimatedNavItem
                items={NAV_ITEMS}
                keys={(item) => item.path}
                native
                state={animationState}
              >
                {(item) => ({ opacity, x }) => (
                  <NavItem
                    onClick={() => this.handleLinkClick(item.path)}
                    style={
                      !isMatched
                        ? {
                            opacity,
                            transform: x.interpolate(
                              (p) => `translate3d(${p}%,0,0)`
                            ),
                          }
                        : {}
                    }
                  >
                    {item.label}
                  </NavItem>
                )}
              </AnimatedNavItem>
            </NavItems>
          )}
        </AnimatedNavItems>
        <Hamburger isOpen={isOpen} onClick={this.handleHamburgerClick} />
      </MainNavWrapper>
    );
  }
}

Nav.propTypes = {
  isMatched: PropTypes.bool.isRequired,
};

export const MainNav = withMedia(Nav);
