import styled, { css } from 'styled-components';
import { media } from 'utils';

export const PageSection = styled.section`
  background-color: ${({ backgroundColor, theme }) =>
    theme.color[backgroundColor] || backgroundColor || 'none'};
  padding: ${({ theme }) =>
    `${theme.layout.gapMobileY} ${theme.layout.gapMobileX}`};

  ${media.large(css`
    padding: ${({ theme }) =>
      `${theme.layout.gapDesktopY} ${theme.layout.gapDesktopX}`};
  `)};
`;
