import styled, { css } from 'styled-components';
import { media } from 'utils';

import { Link } from 'gatsby';
import { Logo } from 'components/common/Logo/Logo';

export const footerTextStyles = css`
  opacity: 0.8;
  display: flex;
  align-items: center;
  font-weight: 300;
  font-size: 14px;
  margin: 0;
  color: ${(props) => props.theme.color.white};
  transition: opacity 0.2s ease;
  text-decoration: none;
`;

export const FooterWrapper = styled.footer`
  background-color: ${(props) => props.theme.color.darkBlue};
  padding: 50px ${(props) => props.theme.layout.gapMobileX};

  ${media.large(css`
    padding: 70px ${(props) => props.theme.layout.gapDesktopX};
  `)};
`;

export const FooterSection = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  max-width: ${(props) => props.theme.layout.contentWidth};
  width: 100%;
  margin: 0 auto;

  ${media.large(css`
    grid-auto-flow: column;
  `)};
`;

export const FooterBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 15px 0;
  position: relative;

  &:after {
    content: '';
    display: block;
    width: 90%;
    height: 1px;
    background-color: ${(props) => props.theme.color.white};
    opacity: 0.3;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 0);
  }

  &:first-of-type {
    padding-top: 20px;

    ${media.large(css`
      padding-top: 0;
    `)};
  }

  &:last-of-type {
    padding-bottom: 0;

    &:after {
      display: none;
    }
  }

  ${media.large(css`
    padding: 0 50px;

    &:after {
      height: 70%;
      width: 1px;
      bottom: auto;
      left: auto;
      right: 0;
      top: 50%;
      transform: translate(0, -50%);
    }

    &:first-of-type {
      padding-left: 0;
    }

    &:last-of-type {
      padding-right: 0;
    }
  `)};
`;

export const FooterText = styled.p`
  ${footerTextStyles};
  margin-top: 15px;

  &:first-of-type {
    margin-top: 0;
  }

  ${({ isClickable }) =>
    isClickable &&
    css`
      cursor: pointer;

      &:hover {
        opacity: 1;
      }
    `};
`;

export const FooterLink = styled(Link)`
  ${footerTextStyles};
  margin-top: 5px;

  &:first-of-type {
    margin-top: 0;
  }

  &:hover {
    opacity: 1;
  }
`;

export const FooterIcon = styled.img`
  margin: 1px 15px 0 0;
  width: 15px;
`;

export const FooterLogo = styled(Logo)`
  justify-self: center;

  ${media.large(css`
    justify-self: start;
  `)};
`;
