import houseIconUrl from 'images/house.svg';
import mailIconUrl from 'images/mail.svg';
import phoneIconUrl from 'images/phone.svg';
import facebookIconUrl from 'images/facebook-logo.svg';

export const EMAIL_ADDRESS = 'biuro@perfect-english.edu.pl';
export const ADDRESS = 'ul. Leśna 15, 19-100 Mońki';
export const PHONE_NUMBER = '+48 502 315 124';
export const FACEBOOK_URL = 'www.facebook.com/angielski.monki';
export const NIP_NUMBER = '546-135-78-20';
export const BANK_ACCOUNT = 'PL 94 1160 2202 0000 0002 6623 8892';
export const GOOGLE_MAP_DIRECTIONS = { lat: 53.400344, lng: 22.805769 };

export const CONTACT_DETAILS = [
  {
    iconUrl: houseIconUrl,
    isClickable: false,
    label: ADDRESS,
  },
  {
    action: '_blank',
    iconUrl: facebookIconUrl,
    isClickable: true,
    label: FACEBOOK_URL,
  },
  {
    action: 'mailto',
    iconUrl: mailIconUrl,
    isClickable: true,
    label: EMAIL_ADDRESS,
  },
  {
    action: 'tel',
    iconUrl: phoneIconUrl,
    isClickable: true,
    label: PHONE_NUMBER,
  },
];
