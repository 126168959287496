import React from 'react';
import PropTypes from 'prop-types';

import { graphql } from 'gatsby';

import { Helmet } from 'react-helmet';

import { ThemeProvider } from 'styled-components';
import { THEME } from 'consts';

import { GlobalStyles } from 'components/common/GlobalStyles/GlobalStyles';

import { Copyright } from './Copyright/Copyright';
import { MainNav } from './MainNav/MainNav';
import { Footer } from './Footer/Footer';

import { LayoutWrapper, LayoutContent } from './LayoutStyles';

export const Layout = ({ children, description, keywords, title }) => (
  <ThemeProvider theme={THEME}>
    <LayoutWrapper>
      <Helmet>
        <title>{title}</title>
        <meta content={description} name="description" />
        <meta content={keywords} name="keywords" />
      </Helmet>
      <MainNav />
      <LayoutContent>{children}</LayoutContent>
      <Footer />
      <Copyright />
      <GlobalStyles />
    </LayoutWrapper>
  </ThemeProvider>
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  description: PropTypes.node,
  keywords: PropTypes.node,
  title: PropTypes.string,
};

Layout.defaultProps = {
  description:
    'Szkoła Języka Angielskiego Perfect English Karolina Karny Mońki',
  keywords:
    'szkoła, język angielski, angielski, nauka, mońki, karolina karny, perfect anglish',
  title: 'Perfect English',
};

export const pageMetaDataFragment = graphql`
  fragment PageMetaDataFragment on MarkdownRemark {
    frontmatter {
      templateKey
      pageDescription
      pageKeywords
      pageTitle
    }
  }
`;
