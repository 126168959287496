import React from 'react';

import { NAV_PATH, NAV_ITEMS, CONTACT_DETAILS } from 'consts';

import logoUrl from 'images/logo-white.svg';
import { handleContactClick } from 'utils';

import { ThirdHeader } from 'components/common/Typography';

import {
  FooterWrapper,
  FooterSection,
  FooterBox,
  FooterLink,
  FooterLogo,
  FooterText,
  FooterIcon,
} from './FooterStyles';

export const Footer = () => (
  <FooterWrapper>
    <FooterSection>
      <FooterLogo imgUrl={logoUrl} to={NAV_PATH.HOME} />
      <FooterBox>
        <ThirdHeader isBolded isLight isUppercased>
          Menu
        </ThirdHeader>
        {NAV_ITEMS.map(({ label, path }) => (
          <FooterLink key={path} to={path}>
            {label}
          </FooterLink>
        ))}
      </FooterBox>
      <FooterBox>
        <ThirdHeader isBolded isLight isUppercased>
          Kontakt
        </ThirdHeader>
        {CONTACT_DETAILS.map(({ action, iconUrl, isClickable, label }) => (
          <FooterText
            key={label}
            isClickable={isClickable}
            {...(isClickable
              ? { onClick: () => handleContactClick(action, label) }
              : {})}
          >
            <FooterIcon alt={label} src={iconUrl} />
            {label}
          </FooterText>
        ))}
      </FooterBox>
    </FooterSection>
  </FooterWrapper>
);
