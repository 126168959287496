import styled, { css } from 'styled-components';
import { media } from 'utils';

import { Link } from 'gatsby';

export const LogoLink = styled(Link)`
  display: block;
  width: 200px;

  ${media.large(css`
    width: 220px;
  `)};
`;

export const LogoImg = styled.img`
  display: block;
  max-width: 100%;
  margin: 0;
`;
