import React from 'react';
import PropTypes from 'prop-types';

import { HamburgerNav, Wrapper } from './HamburgerStyles';

export const Hamburger = ({ isLight, isOpen, onClick }) => (
  <Wrapper onClick={onClick}>
    <HamburgerNav isLight={isLight} isOpen={isOpen} />
  </Wrapper>
);

Hamburger.defaultProps = {
  isLight: false,
};

Hamburger.propTypes = {
  isLight: PropTypes.bool,
  isOpen: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};
