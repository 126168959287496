export const THEME = {
  color: {
    dark: '#141720',
    darkBlue: '#0a1331',
    darkGray: '#5d5f65',
    dustyGray: '#A1A2A0',
    error: '#F21430',
    gray: '#4b4b4b',
    light: '#f8f8f8',
    lightBlue: '#21273d',
    lightGray: '#efefef',
    primary: '#ca1d1e',
    shadow: '0px 2px 10px 2px rgba(231, 234, 242, 1)',
    silver: '#C2C2C2',
    success: '#60B700',
    white: '#ffffff',
  },
  layout: {
    contentWidth: '1200px',
    contentWidthSmall: '700px',
    gapBoxesDesktop: '50px',
    gapBoxesMobile: '30px',
    gapDesktopX: '30px',
    gapDesktopY: '90px',
    gapMobileX: '20px',
    gapMobileY: '50px',
    navDesktopHeight: '80px',
    navMobileHeight: '60px',
  },
};
