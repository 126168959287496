import React from 'react';
import PropTypes from 'prop-types';

import { LogoLink, LogoImg } from './LogoStyles';

export const Logo = ({ className, imgUrl, to }) => (
  <LogoLink className={className} to={to}>
    <LogoImg alt="Logo" src={imgUrl} />
  </LogoLink>
);

Logo.propTypes = {
  className: PropTypes.string,
  imgUrl: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
};

Logo.defaultProps = {
  className: '',
};
