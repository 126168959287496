import React from 'react';

import { css } from 'styled-components';
import Media from 'react-media';

const breakpoints = {
  large: 992,
  medium: 768,
  small: 576,
  xLarge: 1170,
};

export const media = Object.keys(breakpoints).reduce((acc, label) => {
  const emSize = breakpoints[label] / 16;

  acc[label] = (args) => css`
    @media (min-width: ${emSize}em) {
      ${args};
    }
  `;

  return acc;
}, {});

export const withMedia = (WrappedComponent, mq = breakpoints.large) => (
  props
) => (
  <Media query={`(min-width: ${mq}px)`}>
    {(isMatched) => <WrappedComponent isMatched={isMatched} {...props} />}
  </Media>
);
