import React from 'react';

import { Description } from 'components/common/Typography';
import { CopyrightWrapper } from './CopyrightStyles';

export const Copyright = () => (
  <CopyrightWrapper>
    <Description color="lightGray" isSmall>
      © Copyright Patryk Karny
    </Description>
  </CopyrightWrapper>
);
